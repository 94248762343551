export interface Product {
  _id: string;
  extendedWarrantyCost: number;
  subtype: string;
  priceB2b: number;
  priceB2c: number;
  imgUrls: any[];
  properties: Properties;
  type: string;
  longDescription: string;
  shortDescription: string;
  buyingCarPrice: any;
  TechnischeProduktdaten: string[];
  recommand: boolean;
  warrantyLink: string;
  capacity: string;
  pdfUrl: string;
  instock: boolean;
  applicableMaxCapacity: number;
  electricCarConsumption: number;
  range: string;
  Highlights: string;
  brand: string;
  videoLink: string;
  name: string;
  Herstellerinformation: string;
  allowedInverter: string;
  colors: any[];
  Garantie: string;
  compatible_products: CompatibleProduct[];
  bullet_point_list: string[];
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  userHistory: string;
  __v: number;
  user: User;
  installerAccess: any;
  installerId?: boolean;
  tax: number;
  bulkPrice?: {
    [key: string]: IBulkPrice[];
  };
}

export interface IBulkPrice {
  key: number;
  quantity: number;
  priceB2b: number;
  priceB2c: number;
}

export interface Properties {
  Display: boolean;
}

export interface CompatibleProduct {
  name: string;
  type: string;
  _id: string;
}

export interface User {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  isFromFirebase: boolean;
  mobile: string;
  address: string;
  installerCity: string;
  installerPartner: string;
  lastLogin: string;
  loginCount: number;
  visitWeb: number;
  visiteDate: string;
  role: string;
  selectedViews: SelectedViews;
  language: string;
  createdAt: string;
  updatedAt: string;
  downloads: any[];
  refCode: RefCode[];
  credits: any[];
  __v: number;
  hubSpotContactId: string;
}

export interface SelectedViews {
  SAVED_PROJECTS: SavedProjects;
  CHANGE_REQUEST: ChangeRequest;
  PRODUCTS: Products;
  USERS: Users;
  QUOTES: Quotes;
  INSTALLERS: Installers;
  WEBINAR_DETAILS: WebinarDetails;
  PARTNER_CITIES: PartnerCities;
}

export interface SavedProjects {
  tabs: any[];
}

export interface ChangeRequest {
  tabs: any[];
}

export interface Products {
  tabs: any[];
}

export interface Users {
  tabs: any[];
}

export interface Quotes {
  tabs: any[];
}

export interface Installers {
  tabs: any[];
}

export interface WebinarDetails {
  tabs: any[];
}

export interface PartnerCities {
  tabs: any[];
}

export interface RefCode {
  quoteId: string;
  refCode: string;
  _id: string;
}

export interface IGetProductsResponse {
  data: Product[];
}

export interface ProductDataType {
  key: string;
  name: string;
  brand: string;
  type: string;
  imgUrls: any;
  range: any;
  priceB2b: any;
  priceB2c: any;
  buyingCarPrice: any;
  extendedWarrantyCost: any;
  warrantyLink: any;
  longDescription: any;
}

export interface IAddInstallerDTO {
  productId: string;
  iids: string[];
  isDuplicate?: boolean;
}

export interface IDuplicareProductDTO {
  productId: string;
  installerId: string;
}

export interface IUpdateInstallerDTO {
  Iid: string;
  productId: string;
  updatedObject: {
    isStandard: false;
  };
  isDuplicate?: boolean
}

export interface IUpdateBulkPricesDTO {
  iid: string | undefined;
  productId: string;
  bulkPrices: any;
  isDuplicate?: boolean;
}

export enum ProductEndpoints {
  PRODUCT = "/product",
  SEARCH = "/search",
  PRODUCT_INSTALLER = "/productinstaller",
  UPDATE_INSTALLER = "/updateinstaller",
  REMOVE_INSTALLER = "/removeinstaller",
  UPDATE = "/update",
}

export interface IgetProductEndpoints {
  installerId?: string;
  productId?: string;
}

export const getProductEndpoints = ({
  installerId,
  productId,
}: IgetProductEndpoints) => ({
  GET_PRODUCTS: `${ProductEndpoints.PRODUCT}?installerId=${installerId}`,
  PRODUCT_INSTALLER: `${ProductEndpoints.PRODUCT}${ProductEndpoints.PRODUCT_INSTALLER}`,
  UPDATE_INSTALLER: `${ProductEndpoints.PRODUCT}${ProductEndpoints.UPDATE_INSTALLER}`,
  REMOVE_INSTALLER: `${ProductEndpoints.PRODUCT}${ProductEndpoints.REMOVE_INSTALLER}`,
  DUPLICATE_PRODUCT: `${ProductEndpoints.PRODUCT}/${installerId}/${productId}`,
  UPDATE_PRODUCT: `${ProductEndpoints.PRODUCT}/${ProductEndpoints.UPDATE}/${productId}?isDuplicate=true`,
  DELETE_PRODUCT: `${ProductEndpoints.PRODUCT}/delete?isDuplicate=true`,
  BULK_UPDATE: `${ProductEndpoints.PRODUCT}/add-bulk-price`
});
